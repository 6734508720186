@import "~@ng-select/ng-select/themes/default.theme.css";
/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';

// 2. Include any default variable overrides here
// Default variable overrides
// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap/scss/root";
// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/tooltip";
//@import "node_modules/bootstrap/scss/_pagination.scss";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";


body {
  position: relative;

  -webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;

}

.btn-primary:hover {
  background-color: var(--bs-btn-hover-bg)!important;
}

.ng-select.virtual-scroll .ng-dropdown-panel, .ng-dropdown-panel.virtual-scroll {
  width: 350px !important;
}

.ng-select .ng-dropdown-panel {
  width: auto !important;
  max-width: 750px;
  min-width: 100%;
}
.ng-select:not(.narrow-select) .ng-select-container .ng-value {
  display: flex;
  align-items: center;
}
.ng-select:not(.narrow-select) .ng-select-container .ng-value-label {
  white-space: normal!important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal!important;
}

.ng-select.custom .ng-select-container {
  //margin-top: 0.5rem;
  background-color: var(--background-contrast-grey);
  box-shadow: inset 0 -2px 0 0 var(--border-plain-grey);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 0;
  min-height: 40px;
  //margin-top: 0.5rem;
  margin-right: 1rem !important;

  .ng-placeholder {
    color: var(--text-title-grey);
  }
}

.ng-select.custom.fr-select-group--valid .ng-select-container {
  box-shadow: inset 0 -2px 0 0 var(--border-plain-success);
}

.ng-select .ng-select-container {
  color: var(--text-title-grey);
}

.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--blue-france-sun-113-625);
  color: white;
  border-radius: 10px;
  padding: 3px;
}

.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none;
  background-color: transparent;
}

.ng-select.fr-select--valid .ng-select-container {
  box-shadow: inset 0 -2px 0 0 var(--border-plain-success) !important;
}

.ng-select.fr-select--error .ng-select-container {
  box-shadow: inset 0 -2px 0 0 var(--border-plain-error) !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24' ><path fill='%23161616' d='M12,13.1l5-4.9l1.4,1.4L12,15.9L5.6,9.5l1.4-1.4L12,13.1z'/></svg>");
}

.ng-placeholder {
  top: unset !important;
}

.txt-blue {
  color: var(--text-action-high-blue-france) !important;
}

.fr-icon--xxl::before,
.fr-icon--xxl::after {
  --icon-size: 4rem !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fr-icon-add-message::before,
.fr-icon-add-message::after {
  -webkit-mask-image: url("./assets/add-comment.svg");
  mask-image: url("./assets/add-comment.svg");
}

.fr-highlight {
  box-shadow: inset 0.25rem 0 0 0 var(--background-action-high-blue-france) !important;
}

.table {
  font-size: 13px !important;
}

table {
  p {
    font-size: 13px !important;
  }

  span {
    font-size: 13px !important;
  }

  a {
    font-size: 12px !important;
  }
}

.scroll-horiz {
  display: inline-flex;
  overflow-x: auto;
  max-height: 80vh;
  max-width: 100%;
}

.table-fs {
  max-height: 85vh !important;
}

.ng-select.ng-select-disabled > .ng-select-container .ng-value-container .ng-input > input {
  cursor: not-allowed;
}

.ng-select.ng-select-disabled > .ng-select-container .ng-value-container .ng-placeholder {
  color: #6a6a6a;
}

.ng-select.ng-select-disabled > .ng-select-container {
  //  background-color: #929292;
  color: #6a6a6a;
  box-shadow: inset 0 -2px 0 0 #cecece;
  background-color: #f0f0f0;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #cecece;
  color: black;
}

.warning-locked {
  background-color: var(--background-contrast-warning);
  color: var(--text-default-warning);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: var(--text-label-grey) !important;
}

@media (min-width: 62em) {
  .fr-logo--lg.fr-logo--lg {
    font-size: 1.3125rem;
  }
}

@media (min-width: 62em) {
  .fr-logo--lg.fr-logo--lg::after {
    min-width: 3.28125rem;
    background-size: 6.5625rem 4.6875rem;
    background-position: 0 calc(100% + 2.34375rem);
    padding-top: 2.76042rem;
  }
}

[data-fr-theme="dark"] .fr-header__logo .fr-logo::after {
  background-position: -3.2rem 100%;
}

.fr-stepper {
  margin-bottom: 0px !important;
}

.h6bis {
  font-size: 1rem;
}

.pie-label {
  font-size: 20px !important;
  stroke: red;
}

.ngx-graph-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.legend-label-color {
  width: 30px !important;
  height: 25px !important;
  border-radius: 0 !important;
}

ngx-charts-legend-entry span {
  display: flex;
  align-items: center;
}

.legend-label-text {
  font-size: 14px !important;
  overflow: unset !important;
}

.ngx-charts,
text {
  fill: var(--text-active-grey);
}

.chart-legend .legend-label .active .legend-label-text {
  color: var(--text-action-high-grey) !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item:hover {
  color: var(--text-action-high-grey) !important;
}

@media print {
  app-header {
    display: none;
  }
  app-footer {
    display: none;
  }
  app-breadcrumb {
    display: none;
  }
  .hideOnprint {
    display: none;
  }
  app-robot-aide-en-ligne {
    display: none;
  }
  .fr-container--fluid {
    padding: 0 !important;
  }
  table, td, th {
    border: 1px solid !important;
  }

  table {
    border-collapse: collapse !important;
  }
  .fr-table table {
    overflow: visible;
  }
  .fr-tabs {
    --tabs-height: auto !important;
    height: auto !important;
    box-shadow: none !important;

  }
  .fr-tabs::before {
    box-shadow: none !important;
  }
}

.modal-import .modal-xl {
  max-width: 90%;
  max-height: 100%;
}

.fr-card:not(.fr-card--no-border):not(.fr-card--shadow) {
  box-shadow: none !important;
  border: 1px solid var(--border-default-grey);
}


.custom-dp-group {
  position: relative;
  display: flex;
  height: fit-content;
}

.fr-input-group--error .custom-dp-group .fr-input {
  box-shadow: inset 0 -2px 0 0 var(--border-plain-error);
}

.custom-dp-group input {
  padding-right: 2.5rem;
}


.custom-dp-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

[ngbDatepickerDayView]:hover:not(.bg-primary), [ngbDatepickerDayView].active:not(.bg-primary) {
  background-color: var(--bs-btn-bg);
  outline: 1px solid var(--bs-border-color);
}

.fr-accordion__btn_custom::after {
  content: none !important;
}

.fr-accordion__btn_custom::before {
  --icon-size: 1rem;
  margin-left: -0.125rem;
  margin-right: 0.25rem;
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: calc((0.75em - var(--icon-size)) * 0.5);
  background-color: currentColor;
  width: var(--icon-size);
  height: var(--icon-size);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-image: url("../node_modules/@gouvfr/dsfr/dist/icons/system/add-line.svg");
  mask-image: url("../node_modules/@gouvfr/dsfr/dist/icons/system/add-line.svg");
  content: "";
}

.fr-accordion__btn[aria-expanded="true"]::before {
  -webkit-mask-image: url("../node_modules/@gouvfr/dsfr/dist/icons/system/subtract-line.svg");
  mask-image: url("../node_modules/@gouvfr/dsfr/dist/icons/system/subtract-line.svg");
}

[data-fr-theme="dark"] .highlighted-text {
  background-color: var(--green-bourgeon-main-640);
  padding: 2px;
}

.highlighted-text {
  background-color: var(--background-action-low-green-tilleul-verveine);
  padding: 2px;
}

.fr-accordion__btn_custom_disable::before {
  content: none !important;
}

.fr-modal__fullscreen {
  height: 100vh !important;
  max-height: 100vh !important;
  min-height: 100vh !important;
}

.modal > .modal-dialog{
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0%   {transform: scale(0.5)}
  100% {transform: scale(1)}
}

app-type-contrat-list {
    width: 100%;
}